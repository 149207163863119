










import moment from 'moment-timezone';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Message, MessagesQueryParams } from '@/models/message.model';
import { FiltersSeismograms } from '@/models/states/filters-state.model';
import { Station } from '@/models/station.model';
import { MESSAGE_TYPES } from '@/constants/message-types.constant';
import { MessagesService } from '@/services/messages.service';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import { DATE_DEFAULT_FORMAT } from '@/constants/app.constants';

const filtersNS: string = 'filters';
const stationsNS: string = 'stations';

@Component({
  name: 'SeismogramsMapView',
  components: {
    RealTimeMap,
  },
})
export default class SeismogramsMapView extends Vue {
  @Getter('getFilters', { namespace: filtersNS }) public filters?: FiltersSeismograms;
  @Getter('getAllStations', { namespace: stationsNS }) stations?: Station[];
  @Action('fetchStations', { namespace: stationsNS }) public fetchStations: any;

  public isLoading: boolean = true;
  public messages: Message[] = [];

  public mounted() {
    this.fetchStations();
    this.onFiltersChange();
  }

  @Watch('filters', { deep: true })
  public onFiltersChange() {
    if (this.filters) {
      this.loadHistory(this.getServerFilters(this.filters));
    }
  }

  public getServerFilters(filters: FiltersSeismograms): Partial<MessagesQueryParams> {
    const formattedDate = moment(filters.date).format(DATE_DEFAULT_FORMAT);
    const from = filters.time ? moment(`${formattedDate} ${filters.time}`).valueOf() : null;
    const to = filters.time ? moment(`${formattedDate} ${filters.time}`).add(filters.displayInterval, 'seconds').valueOf() : null;
    return {
      count: 1000,
      start: 0,
      includeRegional: true,
      messageTypes: [
        MESSAGE_TYPES.SOURCE,
        MESSAGE_TYPES.S_DETECTED,
        MESSAGE_TYPES.P_DETECTED,
        MESSAGE_TYPES.EVENT_ENDED,
        MESSAGE_TYPES.P_SLOBAZ_DETERMINED,
        MESSAGE_TYPES.P_REFINED,
        MESSAGE_TYPES.SOH,
      ],
      sort: { column: 'TIME', ascending: true },
      stationIds: filters.stationIds && filters.stationIds.length ? filters.stationIds : null,
      time: {
        from,
        to,
      },
    };
  }

  private loadHistory(params: Partial<MessagesQueryParams>) {
    if (params.messageTypes === null) {
      this.messages = [];
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    MessagesService.query(params).then(
      (response) => {
        this.isLoading = false;
        this.messages = response.elements;
      },
      () => {
        this.isLoading = false;
        this.messages = [];
      },
    );
  }
}
