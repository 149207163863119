






























import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Message, MessagesQueryParams } from '@/models/message.model';
import { Station } from '@/models/station.model';
import { FiltersSeismograms } from '@/models/states/filters-state.model';
import TableDetailsDialog from '@/components/shared/TableDetailsDialog.component.vue';
import { MessagesService } from '@/services/messages.service';
import { DATE_DEFAULT_FORMAT } from '@/constants/app.constants';

const filtersNS: string = 'filters';

@Component({
  name: 'SeismogramsMessagesView',
  components: {
    TableDetailsDialog,
  },
})
export default class SeismogramsMessagesView extends Vue {
  @Getter('getFilters', { namespace: filtersNS }) public filters?: FiltersSeismograms;
  @Action('updateFilters', { namespace: filtersNS }) public updateFilters: any;

  public openDialog: boolean = false;
  public dialogRow: Message | null = null;
  public isLoading: boolean = true;
  public headers: any[] = [
    { text: 'Station', value: 'stationId', sortable: false },
    { text: 'Event Id', value: 'eventId', sortable: false },
    { text: 'Type', value: 'type', sortable: false },
    { text: 'Time', value: 'time' },
  ];
  public messages: Message[] = [];
  public totalMessages: number = 0;
  public tableOptions: any = {
    page: 1,
    itemsPerPage: 15,
    sortBy: ['time'],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };

  @Watch('filters', { deep: true })
  public onFiltersChange() {
    if (this.filters) {
      this.tableOptions.page = 0;
      this.tableOptions.page = this.filters.page;
      this.tableOptions.itemsPerPage = this.filters.itemsPerPage;
    }
  }

  @Watch('tableOptions', { deep: true })
  public onTableOptionsChange() {
    if (this.filters?.page !== this.tableOptions.page || this.filters?.itemsPerPage !== this.tableOptions.itemsPerPage) {
      this.updateFilters({
        itemsPerPage: this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
      });
    }
    if (this.filters) {
      this.loadHistory(this.getServerFilters(this.filters));
    }
  }

  public getServerFilters(filters: FiltersSeismograms): Partial<MessagesQueryParams> {
    const formattedDate = moment(filters.date).format(DATE_DEFAULT_FORMAT);
    const from = filters.time ? moment(`${formattedDate} ${filters.time}`).valueOf() : null;
    const to = filters.time ? moment(`${formattedDate} ${filters.time}`).add(filters.displayInterval, 'seconds').valueOf() : null;
    return {
      count: this.tableOptions.itemsPerPage,
      start: (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
      includeRegional: true,
      messageTypes: filters.messageTypes && filters.messageTypes.length ? filters.messageTypes : null,
      sort: { column: 'TIME', ascending: !_.get(this.tableOptions, 'sortDesc[0]', false) },
      stationIds: filters.stationIds && filters.stationIds.length ? filters.stationIds : null,
      time: {
        from,
        to,
      },
    };
  }

  public onRowClick(row: Message) {
    this.openDialog = true;
    this.dialogRow = row;
  }

  public onDialogDismiss() {
    this.openDialog = false;
    this.dialogRow = null;
  }

  private loadHistory(params: Partial<MessagesQueryParams>) {
    if (params.messageTypes === null) {
      this.messages = [];
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    MessagesService.query(params).then(
      (response) => {
        this.isLoading = false;
        this.messages = response.elements;
        this.totalMessages = response.totalCount;
      },
      () => {
        this.isLoading = false;
        this.messages = [];
      },
    );
  }
}
