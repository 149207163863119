import { render, staticRenderFns } from "./Seismograms.view.vue?vue&type=template&id=539f898c"
import script from "./Seismograms.view.vue?vue&type=script&lang=ts"
export * from "./Seismograms.view.vue?vue&type=script&lang=ts"
import style0 from "./Seismograms.view.vue?vue&type=style&index=0&id=539f898c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabs})
