














import { Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { FiltersSeismograms } from '@/models/states/filters-state.model';
import { QueryService } from '@/services/query.service';
import OptionsSeismograms from '@/components/options-panels/OptionsSeismograms.component.vue';

const filtersNS: string = 'filters';

@Component({
  name: 'SeismogramsView',
  components: {
    OptionsSeismograms,
  },
})
export default class SeismogramsView extends Vue {
  @Getter('getFilters', { namespace: filtersNS }) public filters?: FiltersSeismograms;

  public selectedTab: number = 0;
  public isFiltersOpen: boolean = true;

  public mounted() {
    this.onRouteChange();
  }

  public onTabChange() {
    if (!this.filters) {
      return;
    }
    switch (this.selectedTab) {
      case 1:
        this.$router.push({ name: 'seismograms.messages', query: QueryService.convertQueryParamsToString(this.filters) });
        break;
      case 2:
        this.$router.push({ name: 'seismograms.map', query: QueryService.convertQueryParamsToString(this.filters) });
        break;
      default:
        this.$router.push({ name: 'seismograms.readings', query: QueryService.convertQueryParamsToString(this.filters) });
    }
  }

  @Watch('$route.name')
  public onRouteChange() {
    switch (this.$route.name) {
      case 'seismograms.messages':
        this.selectedTab = 1;
        break;
      case 'seismograms.map':
        this.selectedTab = 2;
        break;
      default:
        this.selectedTab = 0;
    }
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }
}
