





















































import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DISPLAY_INTERVALS_SECONDS } from '@/constants/filters.constants';
import { DATE_DEFAULT_FORMAT, DATE_HOUR_FORMAT, NS_FILTERS, NS_STATIONS } from '@/constants/app.constants';
import { Options } from '@/models/app.model';
import { FiltersSeismograms } from '@/models/states/filters-state.model';
import { HumanizedEvent } from '@/models/event.model';
import { Station } from '@/models/station.model';
import { QueryService } from '@/services/query.service';
import BPFilterSelect from '@/components/shared/BPFilterSelect.component.vue';
import ChannelsTreeSelect from '@/components/shared/ChannelsTreeSelect.component.vue';
import DateTimeSelector from '@/components/shared/DateTimeSelector.component.vue';
import FilterTypeSelect from '@/components/shared/FilterTypeSelect.component.vue';
import MessageTypeSelect from '@/components/shared/MessageTypeSelect.component.vue';
import NodeSelect from '@/components/shared/NodesSelect.component.vue';
import StationsSelect from '@/components/shared/StationsSelect.component.vue';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';
import UnitSelect from '@/components/shared/UnitSelect.component.vue';

@Component({
  name: 'OptionsSeismograms',
  components: {
    BPFilterSelect,
    ChannelsTreeSelect,
    DateTimeSelector,
    FilterTypeSelect,
    MessageTypeSelect,
    NodeSelect,
    StationsSelect,
    StationsSelectTree,
    UnitSelect,
  },
})
export default class OptionsSeismograms extends Vue {
  @Getter('getStationLocations', { namespace: NS_STATIONS }) stations?: Station[];
  @Action('fetchStationLocations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Getter('getFilters', { namespace: NS_FILTERS }) public filters?: FiltersSeismograms;
  @Action('updateFilters', { namespace: NS_FILTERS }) public updateFilters: any;
  @Prop({ default: true }) public isOpen!: number;
  @Prop({ default: () => false }) public showMessageType: boolean = false;
  @Prop({ default: () => false }) public hideNodesFilter: boolean = false;
  @Prop({ default: () => false }) public hideChannelsFilter: boolean = false;

  public isOpenInner: number | undefined = 0;
  public nodes: Options<number>[] = [];
  public innerFilters?: Partial<FiltersSeismograms> = {};
  public displayIntervals: Options<number>[] = DISPLAY_INTERVALS_SECONDS;
  public knownDisplayIntervals: number[] = [];
  public events: HumanizedEvent[] = [];

  public mounted() {
    this.knownDisplayIntervals = this.displayIntervals.map((displayInterval) => displayInterval.value);
    this.innerFilters = _.cloneDeep(this.filters);

    if (_.isEmpty(this.$route.query)) {
      this.onFiltersChange();
    } else {
      this.applyUrlParams();
    }

    this.fetchStations();
  }

  @Watch('isOpen')
  public onOpenChange() {
    this.isOpenInner = this.isOpen ? 0 : undefined;
  }

  @Watch('$route')
  public onRouteChange() {
    this.applyUrlParams();
  }

  @Watch('filters')
  public onFiltersChange() {
    this.innerFilters = _.cloneDeep(this.filters);
    this.updateQueryParams();
  }

  @Watch('stations')
  public onStationsChange() {
    if (this.innerFilters && this.stations) {
      if (!this.innerFilters.stationIds) {
        this.innerFilters.stationIds = _.get(this.stations, '[0].stationId', null);
      }
      this.nodes = _(this.stations)
        .map((station) => station.nodes.map((node) => node.nodeId))
        .flatten()
        .uniq()
        .map((nodeId) => ({
          name: `Node ${nodeId}`,
          value: nodeId,
        }))
        .value();
      this.applyFilters();
    }
  }

  public scrollBack() {
    if (this.innerFilters) {
      const filterDate = moment(`${this.innerFilters.date} ${this.innerFilters.time}`, 'YYYY-MM-DD HH:mm:ss');
      const newFilterDate = filterDate.subtract(this.innerFilters.displayInterval, 'seconds');
      this.innerFilters.date = newFilterDate.format(DATE_DEFAULT_FORMAT);
      this.innerFilters.time = newFilterDate.format(DATE_HOUR_FORMAT);
      this.applyFilters();
    }
  }

  public scrollForward() {
    if (this.innerFilters) {
      const filterDate = moment(`${this.innerFilters.date} ${this.innerFilters.time}`, 'YYYY-MM-DD HH:mm:ss');
      const newFilterDate = filterDate.add(this.innerFilters.displayInterval, 'seconds');
      this.innerFilters.date = newFilterDate.format(DATE_DEFAULT_FORMAT);
      this.innerFilters.time = newFilterDate.format(DATE_HOUR_FORMAT);
      this.applyFilters();
    }
  }

  public humanizeDuration(): string {
    return moment.duration(this.filters?.displayInterval, 'seconds').humanize();
  }

  public applyFilters(filters: Partial<FiltersSeismograms> | undefined = this.innerFilters) {
    if (_.isEqual(filters, this.filters)) {
      return false;
    }

    if (filters && filters.date && filters.time) {
      const unixTimestamp = moment(`${filters.date} ${filters.time}`, 'YYYY-MM-DD HH:mm:ss').valueOf();
      if (unixTimestamp) {
        _.set(filters, 'unixTimestamp', unixTimestamp);
      }
      this.updateFilters(filters);
    }

    return true;
  }

  public updateQueryParams() {
    const omitKeys: string[] = ['date', 'time'];

    _(this.innerFilters).each((value, key) => {
      if (value === null) {
        omitKeys.push(key);
      }
    });

    const newQueryParams = _.omit(this.innerFilters, omitKeys);
    const queryParams = _.omit(this.getQueryParams(), omitKeys);

    if (_.isEqual(newQueryParams, queryParams)) {
      return;
    }

    this.$router
      .push(
        _.extend({}, this.$route, {
          query: QueryService.convertQueryParamsToString(newQueryParams),
        }),
      )
      .catch(() => {});
  }

  private applyUrlParams() {
    if (!_.isEmpty(this.$route.query)) {
      this.applyFilters(this.getQueryParams());
    }
  }

  private getQueryParams() {
    if (!_.isEmpty(this.$route.query)) {
      const queryParams = {
        unit: QueryService.getUnit(this.$route),
        filterType: QueryService.getFilterType(this.$route),
        bpFilter: QueryService.getBPFilter(this.$route),
        channels: QueryService.getSimpleChannels(this.$route) || this.filters?.channels,
        date: moment(parseInt(this.$route.query.unixTimestamp as string, 10)).format(DATE_DEFAULT_FORMAT),
        displayInterval: parseInt(this.$route.query.displayInterval as string, 10),
        itemsPerPage: this.$route.query.page ? parseInt(this.$route.query.itemsPerPage as string, 10) : null,
        messageTypes: QueryService.getMessageTypes(this.$route),
        nodes: QueryService.getNodes(this.$route) || this.filters?.nodes,
        page: this.$route.query.page ? parseInt(this.$route.query.page as string, 10) : null,
        stationIds: QueryService.getStations(this.$route),
        time: moment(parseInt(this.$route.query.unixTimestamp as string, 10)).format(DATE_HOUR_FORMAT),
        unixTimestamp: parseInt(this.$route.query.unixTimestamp as string, 10),
      };

      const omitKeys: string[] = [];

      _(queryParams).each((value, key) => {
        if (value === null) {
          omitKeys.push(key);
        }
      });

      return _.omit(queryParams, omitKeys);
    }
    return {};
  }
}
