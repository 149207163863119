























import moment from 'moment-timezone';
import _ from 'lodash';
import { Action, Getter } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import { DATE_DEFAULT_FORMAT, DATE_HOUR_FORMAT, MAX_SECONDS_TO_DISPLAY_READINGS, NS_FILTERS } from '@/constants/app.constants';
import { ReadingsRequest, ReadingsResponse } from '@/models/response.model';
import { FiltersSeismograms } from '@/models/states/filters-state.model';
import { MessagesQueryParams } from '@/models/message.model';
import { ChartConfiguration, PlotLineConfiguration } from '@/models/chart.model';
import { ReadingService } from '@/services/reading.service';
import { MessagesService } from '@/services/messages.service';
import { getNameByChannelId } from '@/filters/channel.filter';
import { roundToDecimals } from '@/utils/math.utils';
import { ChartUtils } from '@/utils/chart.utils';
import ChartsGroup from '@/components/charts/ChartsGroup.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import SplitPanelComponentBase from '@/components/shared/SplitPanelComponentBase';
import { getUnit } from '@/filters/text.filter';

@Component({
  name: 'SeismogramsReadingsView',
  components: {
    ChartsGroup,
    LoadingBar,
  },
})
export default class SeismogramsReadingsView extends SplitPanelComponentBase {
  @Getter('getFilters', { namespace: NS_FILTERS }) public filters?: FiltersSeismograms;
  @Action('updateFilters', { namespace: NS_FILTERS }) public updateFilters: any;

  public isLoading: boolean = true;
  public channelReadings: ChartConfiguration[] = [];
  public plotLines: PlotLineConfiguration[] = [];
  public changeAction: () => void;

  constructor() {
    super();
    this.changeAction = _.debounce(this.onChange.bind(this), 300);
  }

  public mounted() {
    this.changeAction();
  }

  @Watch('filters', { deep: true })
  public onFiltersChange() {
    this.changeAction();
  }

  public onChange() {
    if (this.filters) {
      const stationIds = _.get(this.filters, 'stationIds', null);
      if (stationIds) {
        this.loadHistory(this.getServerFilters(this.filters));
        this.loadMessagesHistory(this.getMessagesServerFilters(this.filters));
      }
    }
  }

  public getMessagesServerFilters(filters: FiltersSeismograms): Partial<MessagesQueryParams> {
    const formattedDate = moment(filters.date).format(DATE_DEFAULT_FORMAT);
    const from = filters.time ? moment(`${formattedDate} ${filters.time}`).valueOf() : null;
    const to = filters.time ? moment(`${formattedDate} ${filters.time}`).add(filters.displayInterval, 'seconds').valueOf() : null;
    return {
      count: 1000,
      start: 0,
      includeRegional: true,
      messageTypes: filters.messageTypes && filters.messageTypes.length ? filters.messageTypes : null,
      sort: { column: 'TIME', ascending: true },
      stationIds: filters.stationIds && filters.stationIds.length ? filters.stationIds : null,
      time: {
        from,
        to,
      },
    };
  }

  public getServerFilters(filters: FiltersSeismograms): ReadingsRequest {
    const formattedDate = moment(filters.date).format(DATE_DEFAULT_FORMAT);
    const from = filters.time ? moment(`${formattedDate} ${filters.time}`).valueOf() : null;
    const to = filters.time ? moment(`${formattedDate} ${filters.time}`).add(filters.displayInterval, 'seconds').valueOf() : null;
    return {
      preferredUnits: filters.unit ? filters.unit : null,
      applyFilters: filters.filterType ? [{ type: filters.filterType }] : [],
      count: 10000,
      channels: filters.channels ? _(filters.channels).flatten().value() : null,
      from,
      nodes: filters.nodes,
      stationIds: filters.stationIds && filters.stationIds.length ? filters.stationIds : null,
      timeGrouping: {
        unit: 'MILLIS',
        value: (25 * filters.displayInterval) / 60,
      },
      to,
    };
  }

  public onZoomChange(event: { start: number; end: number }) {
    const start = moment(event.start);
    const end = moment(event.end).add(1, 'seconds');
    this.updateFilters({
      unixTimestamp: start.valueOf(),
      time: start.format(DATE_HOUR_FORMAT),
      date: start.toISOString().substr(0, 10),
      displayInterval: end.diff(start, 'seconds'),
    });
  }

  private loadHistory(params: ReadingsRequest) {
    this.saveScrollPosition();
    this.channelReadings = [];
    const displayInterval = this.filters ? this.filters.displayInterval : MAX_SECONDS_TO_DISPLAY_READINGS;

    if (displayInterval > MAX_SECONDS_TO_DISPLAY_READINGS) {
      setTimeout(() => {
        this.channelReadings = this.generateChannels(true);
      }, 500);
    } else {
      this.isLoading = true;

      ReadingService.query(params).then(
        (response: ReadingsResponse) => {
          this.isLoading = false;
          const channelReadings = this.generateChannels();
          channelReadings.forEach((channel) => {
            _.each(response.stations, (station, stationId) => {
              _.each(station.nodes, (node, nodeId) => {
                const path = `nodes.${nodeId}.channels.${channel.key}`;
                const dataPath = `${path}.sensorReadings`;
                const unitsPath = `${path}.units`;
                const channelStatuses = `${path}.channelStatuses`;
                const units = _.get(station, unitsPath, '');
                const statuses = _.get(station, channelStatuses, []);
                const parsedData = _.get(station, dataPath, []).map((data: any) => [
                  data.time,
                  data.value ? roundToDecimals(data.value, 6) : Number.NaN,
                ]);

                const hasDisabledData = statuses.filter((s: any) => s.channelStatus !== 'ENABLED').length > 0;

                channel.series.push({
                  key: `${parseInt(nodeId, 10)}`,
                  name: `${stationId} - Node ${nodeId} - [${getUnit(units) || 'No Unit Set'}]`,
                  units: _.get(station, unitsPath, ''),
                  hasDisabledData,
                  data: parsedData,
                });
              });
            });
          });

          this.channelReadings = channelReadings;
          this.restoreScrollPosition();
        },
        () => {
          this.isLoading = false;
        },
      );
    }
  }

  private loadMessagesHistory(params: Partial<MessagesQueryParams>) {
    if (params.messageTypes === null) {
      this.plotLines = [];
      return;
    }
    MessagesService.query(params).then((response) => {
      this.plotLines = ChartUtils.convertMessagesToPlotLines(response.elements);
    });
  }

  private generateChannels(showEmptyData: boolean = false): ChartConfiguration[] {
    let channelCountToArray = [...Array(6).keys()];
    if (this.filters && this.filters.channels) {
      channelCountToArray = _.sortBy(_.flatten(this.filters.channels));
    }
    const pointsSize = Math.ceil(_.get(this.filters, 'displayInterval', 0) / 60);

    return channelCountToArray.map((channelId) => ({
      name: getNameByChannelId(channelId),
      key: `${channelId}`,
      series: showEmptyData
        ? [
            {
              name: `please select less than ${MAX_SECONDS_TO_DISPLAY_READINGS} seconds to display readings`,
              key: '0',
              data: [...Array(pointsSize).keys()].map((key) => [
                moment(`${this.filters?.date} ${this.filters?.time}`, 'YYYY-MM-DD HH:mm:ss').add(key, 'minutes').valueOf(),
                null,
              ]),
            },
          ]
        : [],
    }));
  }
}
