

















import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Station } from '@/models/station.model';

@Component({
  name: 'StationsSelect',
})
export default class StationsSelect extends Vue {
  @Prop() public model!: string[];
  @Prop() public stations!: Station[];
  @Prop() public disabled?: boolean;
  @Prop() public showRegionalWarning?: boolean;
  @Prop({ default: false }) public multiple?: boolean;

  public innerModel: string[] = [];

  @Emit()
  public onChanged() {
    return this.innerModel;
  }

  public mounted() {
    this.onModelChange();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }

  public onChange() {
    this.$emit('update:model', this.innerModel);
    this.onChanged();
  }
}
